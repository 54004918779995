














import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class InputWithSearch extends Vue {
  @Prop()
  size?: string

  @Prop()
  placeholder?: string

  @Prop()
  value?: string

  @Prop({ default: false })
  enableEnterSubmit?: boolean

  get classObject(): { [key: string]: boolean } {
    const { size } = this
    return {
      'input-with-search__text__size-default': !size,
      'input-with-search__text__size-block': size === 'block',
    }
  }

  private enterSubmit(event: KeyboardEvent) {
    if (!this.enableEnterSubmit) return
    // 日本語入力時のenterはsubmit対象外
    if (event.keyCode === 13) {
      this.$emit('click-event')
    }
  }
}
