






















import { Component, Prop, Mixins, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

import DrillWebApi from '@/mixins/drillsv2/WebApi'
import DrillTransition from '@/mixins/drillsv2/DrillTransition'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import StyleSubjectBackground from '@/components/atoms/StyleSubjectBackground.vue'
import { UnitVictory } from '@/views/student/v3/VictoryUnits.vue'

type Pages = 'explanation' | 'summary' | 'sentence_pattern_explanation' | 'print'

@Component({
  components: {
    ColoredBox,
    ButtonBase,
  },
})
export default class UnitInformationVictory extends Mixins(
  DrillWebApi,
  DrillTransition,
  ClassModeMethods,
  StyleSubjectBackground
) {
  @Prop()
  unit!: UnitVictory

  @Prop()
  subjectCode!: string

  get nameClassObject(): { [key: string]: boolean } {
    return {
      'unit-information__name__sugaku': this.subjectCode === 'su' && !this.unit.is_studied,
      'unit-information__name__sugaku-completed': this.subjectCode === 'su' && this.unit.is_studied,
      'unit-information__name__kokugo': this.subjectCode === 'ko' && !this.unit.is_studied,
      'unit-information__name__kokugo-completed': this.subjectCode === 'ko' && this.unit.is_studied,
      'unit-information__name__eigo': this.subjectCode === 'ei' && !this.unit.is_studied,
      'unit-information__name__eigo-completed': this.subjectCode === 'ei' && this.unit.is_studied,
      'unit-information__name__rika': this.subjectCode === 'ri' || (this.subjectCode === 'kj' && !this.unit.is_studied),
      'unit-information__name__rika-completed':
        this.subjectCode === 'ri' || (this.subjectCode === 'kj' && this.unit.is_studied),
      'unit-information__name__syakai': this.subjectCode === 'sh' && !this.unit.is_studied,
      'unit-information__name__syakai-completed': this.subjectCode === 'sh' && this.unit.is_studied,
    }
  }

  private async handleClickUnitBtn(type: Pages, unitName: string) {
    const { subjectId, subjectName } = this.$route.query
    let nextUrl = `/student/v3/victory/units/page/${type}?unitId=${this.unit.id}&subjectCode=${this.subjectCode}&subjectId=${subjectId}&subjectName=${subjectName}&unitName=${unitName}`
    if (type === 'print') {
      nextUrl = `/student/v3/victory/units/print/${this.unit.id}?unitName=${unitName}&subjectCode=${this.subjectCode}&subjectId=${subjectId}&subjectName=${subjectName}`
    }
    this.$router.push({
      path: nextUrl,
    })
  }
}
